.login-screen-content {
    padding: 50px 30px;
    border: 1px solid var(--border);
    width: 30%;
}

.login-screen-content .logo {
    width: 150px;
    /* height: 100px; */
    display: block;
    margin: auto;
}