:root {
    --border: lightgray;
    --hover: rgb(211, 211, 211, .7);
    --white: #ffffff;
    --white-light: rgb(255, 255, 255, 0.2);
}

.pointer {
    cursor: pointer;
    transition: .3s;
}

.pointer-hover:hover {
    background-color: var(--hover);
}