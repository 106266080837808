.dashboard {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 15fr 85fr;
}

.dashboard .main-content {
    display: flex;
    overflow-y: auto;
}

.dashboard .main-content .content {
    flex-grow: 1;
    overflow-y: auto;
}

.dashboard .sidebar {
    display: flex;
    flex-direction: column;
}

.dashboard .sidebar .logo {
    flex-grow: 1;
}

.dashboard .sidebar .logo .logo-content {
    display: grid;
    height: 100%;
}

.dashboard .sidebar .logo .logo {
    display: block;
    margin: auto;
    width: 50% !important;
}

.dashboard .sidebar .links {
    flex-grow: 3;
}

.dashboard .sidebar .links .link {
    color: var(--white);
    text-decoration: none;
    transition: .3s;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.dashboard .sidebar .links .link:hover,
.dashboard .sidebar .links .link.active {
    background-color: var(--white-light);
}

.dashboard .sidebar .links .link i {
    font-size: 20px;
}